.player {
    display: inline-block;
    width: 100%;
    max-width: 24rem;
    position: relative;
    font-size: 0;
    background-image: url(player.png);
    background-size: cover;

    &:hover, &:focus {
        .player__name {
            background-color: var(--color-black);
        }

        .player__number {
            background-color: var(--color-green);
        }

        .player__phone {
            color: var(--color-green);

            &::before {
                background: var(--color-green);
            }
        }

        &::after {
            opacity: .4;
        }
    }

    &::after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--color-green);
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease;
    }

    &.-board {
        margin-bottom: 4rem;
    }
}

.player__name {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: .5rem 1rem;
    background-color: var(--color-green);
    color: var(--color-white);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    z-index: 1;
    transition: all .3s ease;
}

.player__number {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: .5rem 1rem;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    z-index: 1;
    transition: all .3s ease;
}

.player__img {
    width: 100%;
    min-height: 34rem;
    min-width: 24rem;
}

.player__phone {
    display: block;
    position: absolute;
    bottom: -4rem;
    left: 0;
    padding: .5rem 1rem;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    color: var(--color-black);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.6rem;
    transition: all .3s ease;

    &::before {
        content: '';
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: var(--color-black);
        mask-image: url(phone.svg);
        mask-size: cover;
        margin-right: .6rem;
        transition: all .3s ease;
        position: relative;
        top: .2rem;
    }
}
