.logo {
    display: inline-block;
    position: relative;
    font-size: 0;
    width: 26rem;
    height: 13rem;

    @media (--screen-md-xl) {
        width: 32.5rem;
        height: 16.25rem;
    }

    @media (--screen-xxl) {
        width: 36.5rem;
        height: 18.25rem;
    }

    &::before {
        transition: all .3s ease;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 21rem;
        height: 9rem;
        z-index: 2;

        @media (--screen-md-xl) {
            width: 26.25rem;
            height: 11.25rem;
        }

        @media (--screen-xxl) {
            width: 29.5rem;
            height: 12.5rem;
        }
    }

    &.-header {
        margin-top: .5rem;
        margin-right: auto;
        width: 25.5rem;
        height: 6rem;

        @media (--screen-md-xl) {
            margin-top: 2rem;
            width: 35rem;
            height: 12rem;
        }

        @media (--screen-xxl) {
            margin-top: .5rem;
            width: 31.5rem;
            height: 19rem;
        }

        &::before {
            width: 14rem;
            height: 6rem;

            @media (--screen-md-xl) {
                width: 25rem;
                height: 10rem;
            }

            @media (--screen-xxl) {
                width: 25rem;
                height: 10rem;
            }
        }

        .header.-menu & {
            @media (--screen-xs-xl) {
                margin-top: .5rem;
                width: 25.5rem;
                height: 6rem;
                flex: 0 0 25.5rem;

                &::before {
                    width: 14rem;
                    height: 6rem;
                }

            }
        }
    }
}
