.highlight {
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6rem 0;
    }
}

.highlight__content {
    flex-basis: 30%;
    padding-right: 4rem;
    box-sizing: border-box;

    @media (--screen-lg-xxl) {
        flex-basis: 30%;
        padding-right: 4rem;
        order: -1;
    }
}

.highlight__title {
    color: var(--color-white);
    text-transform: uppercase;
}

.highlight__image {
    max-width: 100%;

    @media (--screen-xs-md) {
        width: 100%;
    }

    @media (--screen-lg-xxl) {
        flex-basis: 50%;
    }
}
