.footer {
    background: var(--color-white);
    color: var(--color-black);

    .container {
        position: relative;
        z-index: 1;
    }
}

.footer__top {
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 1.6rem;
    font-family: var(--font-family);
    font-weight: 500;
    padding: 3rem 0;
    text-align: center;

    @media (--screen-lg-xxl) {
        text-align: right;
    }
}

.footer__partners {
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
    }
}

.footer__bottom {
    padding: 3rem 0 ;
}
