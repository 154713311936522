.partners-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 1rem;
    justify-content: center;

    @media (--screen-lg-xxl) {
        gap: 2rem;
        row-gap: 2rem;
    }
}

.partner-item {
    display: block;
    width: 100%;
    max-width: 14rem;
    position: relative;
    font-size: 0;

    @media (--screen-lg-xxl) {
        max-width: 20rem;
    }

    &:hover, &:focus {
        &::after {
            opacity: .4;
        }
    }

    &::after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--color-green);
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease;
    }
}

.partner-item__image {
    width: 100%;
}
