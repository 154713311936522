.events {
    margin: 0;
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
    }

    .article + &, .events + & {
        padding-top: 0;
    }
}

.events__title {
    font-size: 2.4rem;
    margin: 0 0 2rem;
    text-transform: uppercase;
    color: var(--color-white);
    text-align: center;

    @media (--screen-lg-xxl) {
        font-size: 4rem;
    }
}

.events-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    gap: 2rem;
    row-gap: 2rem;
    justify-content: center;

    li.-big {
        width: 100%;
        text-align: center;
    }
}

.event-item {
    display: block;
    width: 100%;
    max-width: 42.5rem;
    position: relative;
    font-size: 0;
    background-image: url(event.png);
    background-size: cover;
    text-decoration: none;

    &:hover, &:focus {
        .event-item__title {
            background-color: var(--color-black);
        }

        &::after {
            opacity: .4;
        }
    }

    &::after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--color-green);
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease;
    }
}

.event-item__title {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    padding: .5rem 1rem;
    color: var(--color-white);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    z-index: 1;
    transition: all .3s ease;
    background-color: var(--color-green);
}

.event-item__image {
    width: 100%;
}
