.news {
    padding: 12rem 0 15rem;

    @media (--screen-md-xxl ) {
        padding: 15rem 0;
    }

    &.-banner {
        padding: 6rem 0 15rem;
    }

    .container {
        position: relative;
        height: 100%;
    }

    .pagination {
        margin-bottom: 6rem;

        @media (--screen-lg-xxl) {
            margin-bottom: 16rem;
        }
    }
}

.news-list {
    margin: 5rem 0 0;
    padding: 0 0 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    row-gap: 1rem;
    position: relative;
    z-index: 1;
    list-style: none;

    @media (--screen-md-xxl) {
        row-gap: 4rem;
    }

    li {
        flex-basis: 40rem;
    }
}

.news-item {
    width: 100%;
    position: relative;
    display: block;
    font-size: 0;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(179deg, rgba(0,0,0,0.00) 63%, #000000 100%);
    }
}

.news-item__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem;
    text-align: center;
    color: var(--color-white);
    z-index: 2;
    box-sizing: border-box;
}

.news-item__title {
    font-size: 2rem;
    color: var(--color-white);
    font-family: var(--font-family);
    display: block;
    font-weight: 400;
    transition: all .3s ease;

    .news-item:hover &, .news-item:focus & {
        color: var(--color-green);
    }
}

.news-item__date {
    display: block;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 800;
    color: var(--color-white);
    font-family: var(--font-family);
    transition: all .3s ease;

    .news-item:hover &, .news-item:focus & {
        color: var(--color-green);
    }
}

.news-item__image {
    width: 100%;
    min-height: 30rem;
    transition: all .3s ease;

    .news-item:hover &, .news-item:focus & {
        opacity: .7;
    }
}
