table {
    table-layout: fixed;
    width: 100%;
    margin: 0 0 3.2rem;
    border-collapse: collapse;
    color: var(--default-text-color);

    font-size: 1.3rem;
    line-height: 1.4;

    td {
        padding: .8rem .7rem;
        border: .1rem solid var(--color-blue-3);
        vertical-align: top;
    }

    thead {
        font-weight: bold;
        background-color: var(--color-grayscale-1);
    }
}
