.button {
    display: inline-flex;
    margin: 0;
    padding: 1.6rem 2.4rem;
    background-color: var(--color-green);
    color: var(--color-white);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-decoration: none;
    line-height: 1.15;
    border: none;
    cursor: pointer;
    position: relative;
    transition: all .3s ease;
    align-items: center;
    box-sizing: border-box;

    &:hover, &:focus {
        background-color: var(--color-black);
        color: var(--color-white);
    }

    &.-full {
        display: block;
        text-align: center;
    }

    &.-black {
        background-color: var(--color-black);

        &:hover, &:focus {
            background-color: var(--color-white);
            color: var(--color-black);
        }
    }
}
