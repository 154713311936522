a {
    color: var(--color-green);
    text-decoration: underline;
    font-weight: 400;
    font-family: var(--font-family);
    outline: none;

    &:hover, &:focus {
        text-decoration: none;
    }
}
