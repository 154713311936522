/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white: #FFFFFF;
    --color-black: #202020;
    --color-green: #01642d;
    --color-gray: #EEEEEE;

    --font-family: 'Prompt', sans-serif;

    --max-width: 180rem;
    --side-padding: 1rem;
}

@custom-media --screen-xs    (width < 576px);
@custom-media --screen-xs-sm (width < 768px);
@custom-media --screen-xs-md (width < 992px);
@custom-media --screen-xs-lg (width < 1200px);
@custom-media --screen-xs-xl (width < 1400px);

@custom-media --screen-m-md   (width >= 360px)  and (width < 992px);

@custom-media --screen-sm     (width >= 576px)  and (width < 768px);
@custom-media --screen-sm-md  (width >= 576px)  and (width < 992px);
@custom-media --screen-sm-lg  (width >= 576px)  and (width < 1200px);
@custom-media --screen-sm-xl  (width >= 576px)  and (width < 1400px);
@custom-media --screen-sm-xxl (width >= 576px);

@custom-media --screen-md     (width >= 768px)  and (width < 992px);
@custom-media --screen-md-lg  (width >= 768px)  and (width < 1200px);
@custom-media --screen-md-xl  (width >= 768px)  and (width < 1400px);
@custom-media --screen-md-xxl (width >= 768px);

@custom-media --screen-lg     (width >= 992px)  and (width < 1200px);
@custom-media --screen-lg-xl  (width >= 992px)  and (width < 1400px);
@custom-media --screen-lg-xxl (width >= 992px);

@custom-media --screen-xl     (width >= 1200px) and (width < 1400px);
@custom-media --screen-xl-xxl (width >= 1200px);

@custom-media --screen-xxl (width >= 1400px);

@media (--screen-m-md) {
    :root {
        --side-padding: 2rem;
    }
}

@media (--screen-lg-xxl) {
    :root {
        --side-padding: 2rem;
    }
}
