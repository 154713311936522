.game {
    margin: 0;
    padding: 3rem 0;
    text-align: center;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
    }
}

.game__title {
    font-size: 2.4rem;
    margin: 0 0 2rem;
    text-transform: uppercase;
    color: var(--color-green);

    @media (--screen-lg-xxl) {
        font-size: 4rem;
    }
}

.game__content {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 4rem;

    @media (--screen-lg-xxl) {
        gap: 4rem;
    }
}

.game__date {
    font-size: 1.6rem;

    @media (--screen-lg-xxl) {
        font-size: 2.4rem;
    }
}

.game__content__vs {
    font-size: 1.6rem;
    font-weight: 800;
    text-transform: uppercase;

    @media (--screen-lg-xxl) {
        font-size: 2.4rem;
    }
}

.game__image {
    max-width: 10rem;

    @media (--screen-lg-xxl) {
        max-width: 15rem;
    }
}

.game__team {
    margin: 0;
    font-size: 1.5rem;

    @media (--screen-md) {
        font-size: 1.8rem;
    }

    @media (--screen-lg-xxl) {
        font-size: 2rem;
    }
}

.game__content__team {
    flex-basis: 10rem;
    align-self: flex-start;

    @media (--screen-sm-xxl) {
        flex-basis: 22rem;
    }
}
