.team {
    max-width: 66rem;
    margin: 0 auto;
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
    }
}

.team__games {
    font-size: 1.6rem;
    min-width: 66rem;
    border: .1rem solid var(--color-black);

    th {
        text-align: left;
        padding: .5rem;
        background-color: var(--color-black);
        color: var(--color-white)
    }

    tr:nth-child(odd) {
        td {
            background-color: var(--color-gray);
        }
    }

    td {
        padding: .5rem;

        strong {
            display: inline-block;
            background-color: var(--color-green);
            color: var(--color-white);
            padding: 0 .5rem;
        }
    }
}

.team__games__container {
    @media (--screen-xs-sm) {
        overflow-x: scroll;
    }
}

.teams {
    margin: 0;
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
    }

    .article + &, .teams + & {
        padding-top: 0;
    }
}

.teams__title {
    font-size: 2.4rem;
    margin: 0 0 2rem;
    text-transform: uppercase;
    color: var(--color-white);
    text-align: center;

    @media (--screen-lg-xxl) {
        font-size: 4rem;
    }
}

.teams-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    gap: 2rem;
    row-gap: 2rem;
    justify-content: center;

    li.-big {
        width: 100%;
        text-align: center;
    }
}

.team-item {
    display: block;
    width: 100%;
    max-width: 42.5rem;
    position: relative;
    font-size: 0;
    background-image: url(team.png);
    background-size: cover;

    &:hover, &:focus {
        .team-item__title {
            background-color: var(--color-black);
        }

        &::after {
            opacity: .4;
        }
    }

    &::after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--color-green);
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease;
    }
}

.team-item__title {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: .5rem 1rem;
    background-color: var(--color-green);
    color: var(--color-white);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    z-index: 1;
    transition: all .3s ease;
}

.team-item__image {
    width: 100%;
    min-height: 22.5rem;

    @media (--screen-sm-xxl) {
        min-height: 30rem;
    }
}
