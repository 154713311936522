.banner {
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
        display: flex;
        align-items: center;
    }
}

.banner__image {
    width: 100%;

    @media (--screen-lg-xxl) {
        max-width: 60%;
        margin-left: auto;
    }
}

.banner__title {
    color: var(--color-white);
    font-size: 2.4rem;
    font-family: var(--font-family);
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    line-height: 1;
    margin: 0 0 2rem;

    small {
        display: block;
        padding-top: 1rem;
        font-size: 1.8rem;
    }

    @media (--screen-lg-xxl) {
        font-size: 7rem;
        text-align: left;
        position: absolute;
        max-width: 50%;

        small {
            font-size: 2.4rem;
        }
    }
}
