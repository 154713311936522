.article {
    max-width: 66rem;
    margin: 0 auto;
    padding: 3rem 0;

    @media (--screen-lg-xxl) {
        padding: 6rem 0;
    }

    img {
        max-width: 100%;
    }
}

.article__aside {
    max-width: 66rem;
    margin: 0 auto;
    padding: 0 0 3rem;

    @media (--screen-lg-xxl) {
        padding: 0 0 6rem;
    }

    &.-full {
        max-width: 100%;
    }
}

.content--video-container {
    position: relative;
    margin: 1rem 0 3.2rem;

    .video__inner {
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
