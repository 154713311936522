.header {
    display: block;
    width: 100%;
    background-color: var(--color-green);

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4rem;
        row-gap: 2rem;

        @media (--screen-lg-xxl) {
            flex-direction: row;
        }
    }

    .hamburger {
        margin-left: auto;
    }

    &.-menu {
        @media (--screen-xs-md) {
            position: fixed;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            overflow-y: auto;
        }
    }
}

.header__top {
    display: flex;
    align-items: center;
    width: 100%;

    @media (--screen-lg-xxl) {
        width: auto;
    }
}

.header__logo {
    display: block;
    max-width: 10rem;
    outline: none;
}

.header__logo__img {
    width: 100%;
}

.header__nav {
    background-color: var(--color-green);
    display: none;

    @media (--screen-lg-xxl) {
        display: block;
        margin-left: auto;
    }

    .header.-menu & {
        @media (--screen-xs-lg) {
            display: block;
        }
    }
}

.header__nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    gap: 2rem;

    @media (--screen-lg-xxl) {
        flex-direction: row;
    }

    @media (--screen-xl-xxl) {
        gap: 4rem;
    }

    li {
        text-align: center;
    }
}

.header__nav__link {
    font-size: 1.8rem;
    color: var(--color-white);
    text-transform: uppercase;
    font-family: var(--font-family);
    font-weight: 600;
    text-decoration: none;
    transition: all .3s ease;
    padding: 0 .5rem;
    line-height: 1;

    &:hover, &:focus, &.-active {
        color: var(--color-green);
        background-color: var(--color-white);
    }
}
