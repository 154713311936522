.nav-footer {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-footer__item {
    position: relative;
    display: inline-block;
    padding: 0 2.5rem;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 1.5rem;
    color: var(--color-white);
    transition: all .3s ease;

    &:hover, &:focus {
        color: var(--color-orange);

        &::before, &::after {
            background-color: var(--color-orange);
        }
    }
}

.nav-footer__link {
    color: var(--color-blue);
}

.nav-main {
    margin: 0 0 3rem;
    padding: 0;
    background-color: var(--color-white);
    text-align: center;
    list-style: none;
    box-sizing: border-box;

    li {
        margin: 0;
        padding: 1.5rem;
    }

    @media (--screen-xxl) {
        max-height: 5.5rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.nav-main__item {
    position: relative;
    display: inline-block;
    padding: 0 .5rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--color-purple);
    transition: all .3s ease;
    font-weight: 700;

    &:hover, &:focus, &.-active {
        color: var(--color-orange);

        &::before, &::after {
            background-color: var(--color-orange);
        }
    }
}
