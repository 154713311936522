.socials {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;
    width: 100%;

    @media (--screen-lg-xxl) {
        justify-content: flex-end;
    }

    li {
        flex-basis: auto;
        padding: 0;
        font-size: 0;
    }
}

.socials__item {
    display: block;
    padding: 1rem;
    transition: all .3s ease;
    background-color: var(--color-black);
    color: var(--color-white);

    &:hover, &:focus {
        background-color: var(--color-green);
    }

    img {
        color: red;
    }
}
