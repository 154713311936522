h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
    margin: 0 0 2rem;
    font-weight: 800;
    line-height: 1.2;
    color: var(--color-green);
    font-family: var(--font-family);
    display: block;

    &.-center {
        text-align: center;
    }

    &.-white {
        color: var(--color-white);
    }

    &.-black {
        color: var(--color-black);
    }
}

h1, .title-1 {
    margin: .8rem auto 3.2rem;
    font-size: 3.4rem;
    text-align: center;
    text-transform: uppercase;

    @media (--screen-lg-xxl) {
        font-size: 5.8rem;
    }
}

h2, .title-2 {
    font-size: 3.4rem;
    color: var(--color-black);
}

h3, .title-3 {
    font-size: 2.4rem;
}

h4, .title-4 {
    font-size: 2rem;
    color: var(--color-black);
}

h5, .title-5 {
    font-size: 1.8rem;
}

h6, .title-6 {
    font-size: 1.5rem;
}
