.container {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 var(--side-padding);
    box-sizing: border-box;
}

.row {
    &.-green {
        background-color: var(--color-green);
    }

    &.-black {
        background-color: var(--color-black);
    }
}
