body {
    font-family: var(--font-family);
    font-size: 1.6rem;
    line-height: 1.56;
    color: var(--color-purple);
    background: var(--color-blue);
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
