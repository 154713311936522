ul:not([class]) {
    margin: 1rem 0 3.2rem;
    padding: 0 0 0 1.7rem;
    list-style: none;
    line-height: 1.67;

    .article__content & {
        margin-left: auto;
        margin-right: auto;
    }

    li {
        margin: 0 0 .4rem;
        position: relative;

        &::before {
            position: absolute;
            left: -1.7rem;
            top: calc(.83em - .25rem);
            content: '';
            width: .5rem;
            height: .5rem;
            border-radius: 50%;
            background-color: var(--color-white);

            .article & {
                background-color: var(--color-green);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    li > & {
        margin: .8rem 0 0;
    }
}

li > ul > li {
    &::before {
        border-radius: 50%;
    }
}

/* stylelint-disable selector-max-type */
li > ul > li > ul > li {
    &::before {
        border-radius: 0;
    }
}
/* stylelint-enable selector-max-type */
