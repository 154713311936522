figure {
    margin: 0 0 3rem;
    padding: 0;

    img {
        width: 100%;
        margin: 0 auto;
    }

    figcaption {
        margin-top: .4rem;
        font-size: 1.3rem;
        line-height: 1.3;
        color: var(--color-purple);
    }

    &.small, &.medium {
        max-width: 66rem;
        margin: 0 auto 3rem;

        img {
            max-width: 66rem;
        }
    }

    &.small {
        img {
            max-width: 33rem;
        }
    }

    &.center {
        figcaption {
            text-align: center;
        }

        text-align: center;
    }

    &.right {
        text-align: right;
    }

    &.-team {
        @media (--screen-md-xxl) {
            margin-left: -4rem;
            margin-right: -4rem;
        }
    }
}
